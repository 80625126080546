///
/// Editorial by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Footer */

	#footer {
		.copyright {
			color: _palette(fg-light);
			font-size: 0.9em;

			a {
				color: inherit;
			}
		}
	}