///
/// Editorial by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Image */

	img {
			border-radius: _size(border-radius);
		}

	p{
	    clear: both;
	}
	.image {
		border-radius: _size(border-radius);
		border: 0;
		/*display: inline-block;*/
		position: relative;

		&.fit {
			display: block;
			margin: 0 0 _size(element-margin) 0;
			max-width: 100%;
			max-height: 100%;

			img {
				width: 100%;
				height: 100%;
			}
		}
		&.main {
			display: block;
			margin: 0 0 (_size(element-margin) * 1.5) 0;
			/*width: 100%;*/
			max-width: 100%;
			max-height: 100%;

			img {
				width: 100%;
				height: 100%;
			}
		}

		&.left,
		&.right {
			max-width: 40%;
			top: 0.25em;
			clear: both;

			img {
				width: 100%;
			}
		}

		&.left {
			float: left;
			/*padding: 0 1.5em 1em 0;*/
			margin: 0em 1.5em 1em  0;
		}

		&.right {
			float: right;
			/*padding: 0 0 1em 1.5em;*/
			margin: 0em 0 1em 1.5em;
		}

		&.vignette {
			@include breakpoint('xlarge-to-max') {
			max-width: 350px;
			}

			@include breakpoint('<=xlarge') {
			max-width: 240px;
			}

			@include breakpoint('<=small') {
			max-width: 160px;
			}

			@include breakpoint('<=xsmall') {
			max-width: 100px;
			}
		}

		&.vignette-h {
			@include breakpoint('xlarge-to-max') {
			max-height: 160px;
			}

			@include breakpoint('<=xlarge') {
			max-height: 120px;
			}

			@include breakpoint('<=small') {
			max-height: 100px;
			}

			@include breakpoint('<=xsmall') {
			max-height: 90px;
			}
		}

		&.vignette-haute {
			@include breakpoint('xlarge-to-max') {
			max-height: 320px;
			}

			@include breakpoint('<=xlarge') {
			max-height: 240px;
			}

			@include breakpoint('<=small') {
			max-height: 200px;
			}

			@include breakpoint('<=xsmall') {
			max-height: 180px;
			}
		}
	}

	a.image {
		overflow: hidden;

		img {
			@include vendor('transition', 'transform #{_duration(transition)} ease');
		}

		&:hover {
			img {
				@include vendor('transform', 'scale(1.075)');
			}
		}
	}
